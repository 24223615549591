import './bootstrap';

import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import App from './views/App.vue';
import Daily from './views/Daily.vue';
import Monthly from './views/Monthly.vue';
import Sensors from './views/Sensors.vue';
import Accounts from './views/Accounts.vue';

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'daily',
            component: Daily
        },
        {
            path: '/accounts',
            name: 'accounts',
            component: Accounts
        },
        {
            path: '/monthly',
            name: 'monthly',
            component: Monthly
        },
        {
            path: '/sensors',
            name: 'sensors',
            component: Sensors
        },
    ],
    linkActiveClass: 'active'
});

//Vue.component('example-component', require('./components/ExampleComponent.vue'));

const app = new Vue({
    el: '#app',
    components: { App },
    router,
});
