export default {
    SensorsTab() {

        function Init() {

            Polyfill();
        }

        this.Run = function() {

            console.log('Initialising Sensors Tab ...');
            drawSensorCharts();
        }

        Init();
    }
}