export default {
MonthlyTab() {

	var _self = this;
	var _period = new PeriodManager(true);
	var _communicator;
	var _worklogQueryAgent;

	var _totalTimeReport;
	var _internalTasksReport;
	var _logimaOverviewReport;

	var _radcalOverviewReport;
	var _radcalAccountReport;
	var _radcalTypeReport;

	var _displayqOverviewReport;
	var _displayqAccountReport;
	var _displayqTypeReport;

	var _ibaOverviewReport;
	var _ibaAccountReport;
	var _ibaTypeReport;

	var _vacutecOverviewReport;
	var _vacutecAccountReport;
	var _vacutecTypeReport;

	var _rotarexOverviewReport;
	var _rotarexAccountReport;
	var _rotarexTypeReport;

    var _monthlyViewTimeflowReport;

	function Init() {

		Polyfill();
		_communicator = new TempoAPICommunicator(_self);
	}

	this.Run = function() {
		
		PlayLoadingAnimation();
		console.log('Initialising Monthly Tab ...');
		_communicator.GetWorklogsForPeriod(_period.startDate, _period.endDate, function(json) {
			_worklogQueryAgent = new WorklogQueryAgent(json);		
			CollectAndDisplayAll();
		});
	}

	function CollectAndDisplayAll() {

		CreateAllReportObjectsWithRecentWorklogQueryAgent();
		CollectDataForAllReports();
		_self.DrawAllReportsToDashboard();
	}	

	function CreateAllReportObjectsWithRecentWorklogQueryAgent() {

		// if(_lastDayTeamProtocol == null) {
		// 	//_lastDayTeamProtocol = new LastDayTeamProtocol(_worklogQueryAgent, _period.today);
		// 	_lastDayTeamProtocol = new LastDayTeamProtocol(_worklogQueryAgent, _period);
		// 	//// _dailyViewTimeflowReport = new TimeFlowReport(_worklogQueryAgent, _period.GetLastDayOfMonth(), _period.period);
		// }
		_monthlyViewTimeflowReport = new TimeFlowReport(_worklogQueryAgent, _period.GetLastDayOfMonth(), _period.period);
		_totalTimeReport = new TotalTimeReport(_worklogQueryAgent);
		_internalTasksReport = new InternalTasksReport(_worklogQueryAgent);
		_logimaOverviewReport = new OverviewReport(_worklogQueryAgent, 'Logima');

		_radcalOverviewReport = new OverviewReport(_worklogQueryAgent, 'Radcal');
		_radcalAccountReport = new AccountReport(_worklogQueryAgent, 'Radcal');
		_radcalTypeReport = new TypeReport(_worklogQueryAgent, 'Radcal');

		_displayqOverviewReport = new OverviewReport(_worklogQueryAgent, 'DisplayQ');
		_displayqAccountReport = new AccountReport(_worklogQueryAgent, 'DisplayQ');
		_displayqTypeReport = new TypeReport(_worklogQueryAgent, 'DisplayQ');

		_vacutecOverviewReport = new OverviewReport(_worklogQueryAgent, 'VacuTec');
		_vacutecAccountReport = new AccountReport(_worklogQueryAgent, 'VacuTec');
		_vacutecTypeReport = new TypeReport(_worklogQueryAgent, 'VacuTec');

		_rotarexOverviewReport = new OverviewReport(_worklogQueryAgent, 'Rotarex');
		_rotarexAccountReport = new AccountReport(_worklogQueryAgent, 'Rotarex');
		_rotarexTypeReport = new TypeReport(_worklogQueryAgent, 'Rotarex');

		_ibaOverviewReport = new OverviewReport(_worklogQueryAgent, 'Iba');
		_ibaAccountReport = new AccountReport(_worklogQueryAgent, 'Iba');
		_ibaTypeReport = new TypeReport(_worklogQueryAgent, 'Iba');		
	}

	function CollectDataForAllReports() {

		// _lastDayTeamProtocol.CollectData();
		//// _dailyViewTimeflowReport.CollectData();
		_monthlyViewTimeflowReport.CollectData();
		_totalTimeReport.CollectData();
		_internalTasksReport.CollectData();
		_logimaOverviewReport.CollectData();
		_radcalOverviewReport.CollectData();
		_radcalAccountReport.CollectData();
		_radcalTypeReport.CollectData();
		_displayqOverviewReport.CollectData();
		_displayqAccountReport.CollectData();
		_displayqTypeReport.CollectData();
		_vacutecOverviewReport.CollectData();
		_vacutecAccountReport.CollectData();
		_vacutecTypeReport.CollectData();
		_rotarexOverviewReport.CollectData();
		_rotarexAccountReport.CollectData();
		_rotarexTypeReport.CollectData();
		_ibaOverviewReport.CollectData();
		_ibaAccountReport.CollectData();
		_ibaTypeReport.CollectData();		
	}

	this.DrawAllReportsToDashboard = function() {

		_monthlyViewTimeflowReport.Draw('worktime_monthly');
		_internalTasksReport.Draw('internal-tasks');
		_totalTimeReport.Draw('totaltime');
		_logimaOverviewReport.Draw('logima-overview');
		_radcalOverviewReport.Draw('radcal-overview');
		_radcalAccountReport.Draw('radcal-accounts');
		_radcalTypeReport.Draw('radcal-types');
		_displayqOverviewReport.Draw('displayq-overview');
		_displayqAccountReport.Draw('displayq-accounts');
		_displayqTypeReport.Draw('displayq-types');
		_vacutecOverviewReport.Draw('vacutec-overview');
		_vacutecAccountReport.Draw('vacutec-accounts');
		_vacutecTypeReport.Draw('vacutec-types');
		_rotarexOverviewReport.Draw('rotarex-overview');
		_rotarexAccountReport.Draw('rotarex-accounts');
		_rotarexTypeReport.Draw('rotarex-types');
		_ibaOverviewReport.Draw('iba-overview');
		_ibaAccountReport.Draw('iba-accounts');
		_ibaTypeReport.Draw('iba-types');		
	}

	this.NextMonth = function() {

		if(_period.NextReportMonth() == true) {
			PlayLoadingAnimation();
			_self.Run();
		}
	}

	this.PreviousMonth = function() {

		PlayLoadingAnimation();
		_period.PreviousReportMonth();
		_self.Run();
	}

	function PlayLoadingAnimation() {
		var panels = document.getElementById('report-panels');
		for(var i = 0; i < panels.children.length; i++) {
			panels.children[i].innerHTML = '<img  class="mx-auto" src="images/ellipsis.gif" class="monthly-view-loading-animation">';
		}
        document.getElementById("worktime_monthly").innerHTML = '<img class="mx-auto" src="images/ellipsis.gif" class="monthly-view-loading-animation-linechart">';
	}

    function StopDailyViewLoadingAnimation() {
        $("#team-reports-loading-animation").css("visibility", "hidden");
    }

	Init();
}
}