<template>
    <div class="budget-report-wrapper" :id="budgetReportId">
        <div :id="currentMonthBudgetChartId" class="inline budgetchart"></div>
        <div class="inline velocity-report" :id="velocityReportId">
            <p></p>
            <p></p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String
        }
    },
    computed: {
        budgetReportId(){
            return "budget-report-" + this.name;
        },
        currentMonthBudgetChartId(){
            return "current-month-budgetchart-" + this.name;
        },
        velocityReportId(){
            return "velocity-report-" + this.name;
        }
    }
}
</script>

<style>

</style>
