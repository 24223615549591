<template>
  <div class="tab-content">
    <div role="tabpanel" class="tab-pane active" id="monthly">
				<div id="timefield" class="container">
					<div class="row justify-content-center">
						<div class="col-3"></div>
						<button v-on:click="displayPrevious" class="btn btn-default col-1 timeswitch rounded-left" id="previousMonthButton">Previous</button>
						<div class="col-4" id="timefield-period">Period ...</div>
						<button v-on:click="displayNext" class="btn btn-default col-1 timeswitch rounded-right" id="nextMonthButton" >Next</button>
						<div class="col-3"></div>
					</div>
				</div>
				<div class="row" id="report-panels">
					<div class="col-xl-4 col-md-4 col-12 panel report" id="totaltime"></div>
					<div class="col-xl-4 col-md-4 col-12 panel" id='logima-overview'></div>
					<div class="col-xl-4 col-md-4 col-12 panel" id='internal-tasks'></div>
					
					<div class="col-xl-4 col-md-4 col-12 panel" id="radcal-overview"></div>
					<div class="col-xl-4 col-md-4 col-12 panel" id="radcal-accounts"></div>
					<div class="col-xl-4 col-md-4 col-12 panel" id="radcal-types"></div>

					<div class="col-xl-4 col-md-4 col-12 panel" id="vacutec-overview"></div>
					<div class="col-xl-4 col-md-4 col-12 panel" id="vacutec-accounts"></div>
					<div class="col-xl-4 col-md-4 col-12 panel" id="vacutec-types"></div>

					<div class="col-xl-4 col-md-4 col-12 panel" id="iba-overview"></div>
					<div class="col-xl-4 col-md-4 col-12 panel" id="iba-accounts"></div>
					<div class="col-xl-4 col-md-4 col-12 panel" id="iba-types"></div>
					
					<div class="col-xl-4 col-md-4 col-12 panel" id="rotarex-overview"></div>
					<div class="col-xl-4 col-md-4 col-12 panel" id="rotarex-accounts"></div>
					<div class="col-xl-4 col-md-4 col-12 panel" id="rotarex-types"></div>

					<div class="col-xl-4 col-md-4 col-12 panel" id="displayq-overview"></div>
					<div class="col-xl-4 col-md-4 col-12 panel" id="displayq-accounts"></div>
					<div class="col-xl-4 col-md-4 col-12 panel" id="displayq-types"></div>
				</div>
        		<div id="worktime_monthly"></div>
			</div><!-- tab monthly -->
  </div>
</template>

<script>
		import Tab from '../../../public/js/MonthlyTab.js'
    export default {
				methods: {
					
						displayPrevious() {
							this.monthlyTab.PreviousMonth();
						},

						displayNext() {
							this.monthlyTab.NextMonth();
						}
				},

        mounted() {
						console.log('Monthly component mounted.');
						
						this.monthlyTab = new Tab.MonthlyTab();
						this.monthlyTab.Run();
        }
    }
</script>