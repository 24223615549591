<template>
  <div class="tab-content">
    <div role="tabpanel" class="tab-pane active" id="accounts">
      <h5 id="account-tab-heading">
        <em>active Accounts from {{ startDate.toString('dd.MM.yyyy') }} to {{ endDate.toString('dd.MM.yyyy') }}</em>
      </h5>
      <ul id="content" v-if="accounts && worklogs">
        <div v-for="partnerKey in partners" :key="partnerKey">
          <h4>{{ getPartnerName(partnerKey) }}</h4>

          <li
            v-for="project in computedPartners[partnerKey].projects"
            :key="project.key"
            class="project-item"
          >
            <b
              class="project-time-display"
            >{{ project.key }}: - {{ project.timeThisMonth | toHours }} h</b>
            <div
              v-for="acc in project.accounts"
              :key="acc.name"
              class="account-time-display"
            >{{ acc.name }} - {{ acc.timeThisMonth | toHours }} h</div>
          </li>
        </div>
      </ul>

      <div class="error" v-else-if="error">{{ error }}</div>

      <div class="loading" v-else>
        <img class="mx-auto spinner" src="images/ellipsis.gif" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: null,
      accounts: null,
      worklogs: null,
      totalTimeperAccount: null
    };
  },
  mounted() {
    console.log("Accounts component mounted.");
  },
  created() {
    this.error = false;

    axios
      .get("api/proxy/accounts")
      .then(response => {
        var results = response.data.results;
        this.accounts = _.filter(
          results,
          item => item.customer && item.status === "OPEN"
        );
        console.log(response.data.results);
      })
      .catch(error => (this.error = error));

    axios
      .post("api/proxy/worklogs", {
        dateFrom: this.startDate.toString("yyyy-MM-dd"),
        dateTo: this.endDate.toString("yyyy-MM-dd")
      })
      .then(response => {
        this.worklogs = response.data;
        console.log(response.data);
      })
      .catch(error => (this.error = error));
  },
  methods: {
    getPartnerName: function(key) {
      return this.computedPartners[key].name;
    }
  },
  computed: {
    orderedAccounts: function() {
      return _.orderBy(this.accounts, "customer.name");
    },
    partners: function() {
      var tmp = Array.from(
        new Set(this.orderedAccounts.map(x => x.customer.key))
      );
      tmp.splice(tmp.indexOf("Logima"), 1);
      tmp.push("Logima");
      return tmp;
    },
    accountKeys: function() {
      return Array.from(new Set(this.orderedAccounts.map(x => x.key)));
    },
    computedPartners: function() {
      let computedPartners = {};
      this.partners.forEach(
        key => (computedPartners[key] = { name: 0, projects: {} })
      );

      // find full partner name
      for (let partnerKey in computedPartners) {
        computedPartners[partnerKey].name = this.orderedAccounts.find(item => {
          return item.customer.key === partnerKey;
        }).customer.name;
      }

      // iterate all worklogs to build hierarchical structure: partners -> projects -> accounts
      this.worklogs.forEach(item => {
        if (
          item.attributes._Account_ !== undefined &&
          item.attributes._Account_.value !== undefined
        ) {
          let accKey = item.attributes._Account_.value;
          let projectKey = item.issue.projectKey;

          // map account key to customer key
          let keyAcc = this.orderedAccounts.find(account => {
            return account.key === accKey;
          });
          // some accounts are outdated (jira accounts != tempo accounts) -> always check for undefined
          if (keyAcc != undefined) {
            let partnerKey = keyAcc.customer.key;
            let partnerName = keyAcc.customer.name;

            // get project key of this worklog and add project to partner-object
            if (!(projectKey in computedPartners[partnerKey].projects)) {
              computedPartners[partnerKey].projects[projectKey] = {
                key: "",
                timeThisMonth: 0,
                accounts: {}
              };
              computedPartners[partnerKey].projects[
                projectKey
              ].key = projectKey;
            }
            computedPartners[partnerKey].projects[projectKey].timeThisMonth +=
              item.timeSpentSeconds;

            if (this.accountKeys.includes(accKey)) {
              if (
                !(
                  accKey in
                  computedPartners[partnerKey].projects[projectKey].accounts
                )
              ) {
                computedPartners[partnerKey].projects[projectKey].accounts[
                  accKey
                ] = { name: keyAcc.name, timeThisMonth: 0 };
              }
            }
          }
        }
      });

      // find multiples of accounts and assign already computed time
      for (let p in computedPartners) {
        for (let proj in computedPartners[p].projects) {
          for (let acc in computedPartners[p].projects[proj].accounts) {
            if (Object.keys(this.computedAccounts).includes(acc)) {
              computedPartners[p].projects[proj].accounts[
                acc
              ].timeThisMonth = this.computedAccounts[acc].timeThisMonth;
            }
          }
        }
      }
      return computedPartners;
    },
    startDate: function() {
      return Date.today().moveToFirstDayOfMonth();
    },
    endDate: function() {
      return Date.today().moveToLastDayOfMonth();
    },
    computedAccounts: function() {
      let computedAccs = {};
      let accountKeys = this.orderedAccounts.map(x => x.key);
      accountKeys.forEach(
        key =>
          (computedAccs[key] = { totalTime: 0, timeThisMonth: 0, projects: [] })
      );

      this.worklogs.forEach(item => {
        if (
          item.attributes._Account_ !== undefined &&
          item.attributes._Account_.value !== undefined
        ) {
          let accKey = item.attributes._Account_.value;
          if (accKey in computedAccs) {
            computedAccs[accKey].timeThisMonth += item.timeSpentSeconds;

            if (
              !computedAccs[accKey].projects.includes(item.issue.projectKey)
            ) {
              computedAccs[accKey].projects.push(item.issue.projectKey);
            }
          }
        }
      });
      return computedAccs;
    }
  },
  filters: {
    toHours: function(value) {
      if (!value) return 0;
      return (value / 3600).toFixed(2);
    }
  }
};
</script>