<template>
  <div class="tab-content">  
    <div role="tabpanel" class="tab-pane active" id="daily">
        <div class="container" style="margin-top: 50px;">
            <!-- Radiation -->
	  	    <section class="row primary-background">
	            <div class="col-sm-2">
		            <img src="images/icon_radiation.png" alt="radiation icon" class="sensor-icon">
		            <h4>Strahlung</h4>
	            </div>
	            <div class="col-sm-10">
		            <div class="row">			
	                    <div class="col-xs-10" style="height: 116px; padding-right: 0px" id="radiation-chart-1day" data-xaxistitle="Zeit" data-yaxistitle="Strahlung in nSv/h" data-unit="nSv/h" data-charttitle="Strahlung">
	                        <canvas class="sensor-chart chartjs-render-monitor" style="width: 600; height: 115px; padding-left: 0px; padding-right: 0px; display: block;" width="600" height="115"></canvas>
	                    </div>
	                    <div class="col-2 sensor-value text-center">
		                    <p>Aktuell</p>
		                    <h3 id="radiation-chart-1day-value">86</h3>
		                    <p>nSv/h</p>						
	                    </div>
                    </div>
                    <div class="row">
	                    <div class="col-sm-12 sensor-chart-title">
		                    <h4>Letzten 24 Stunden</h4>
	                    </div>	
                    </div>		
	                <div class="row">			
	                    <div class="col-xs-10" style="height: 116px; padding-right: 0px" id="radiation-chart-30days" data-xaxistitle="Zeit" data-yaxistitle="Strahlung in nSv/h" data-unit="nSv/h" data-charttitle="Strahlung">
	                        <canvas class="sensor-chart chartjs-render-monitor" style="width: 600px; height: 115px; padding-left: 0px; padding-right: 0px; display: block;" width="600" height="115"></canvas>
	                    </div>
	                    <div class="col-2 sensor-value text-center">
		                    <p><small class="d-sm-inline">Durchschnittlich</small><span class="d-block d-sm-none">Ø</span></p>
		                    <h3 id="radiation-chart-30days-value">65</h3>
		                    <p>nSv/h</p>						
	                    </div>
                    </div>
                    <div class="row">
	                    <div class="col-sm-12 sensor-chart-title">
		                    <h4>Letzten 30 Tage</h4>
	                    </div>	
                    </div>
	            </div>
            </section><!-- Radiation End -->
        
            <!-- Temperature -->
            <section class="row primary-background">
	            <div class="col-sm-2">
	            	<img src="images/icon_temperature.png" alt="temperature icon" class="sensor-icon">
	            	<h4>Temperatur</h4>
	            </div>
	            <div class="col-sm-10">			
		            <div class="row">			
	                    <div class="col-xs-10" style="height: 116px; padding-right: 0px" id="temperature-chart-1day" data-xaxistitle="Zeit" data-yaxistitle="Temperatur in Celsius" data-unit="°C" data-charttitle="Temperatur">
	                        <canvas class="sensor-chart chartjs-render-monitor" style="width: 600px; height: 115px; padding-left: 0px; padding-right: 0px; display: block;" width="600" height="115"></canvas>
	                    </div>
	                    <div class="col-2 sensor-value text-center">
		                    <p>Aktuell</p>
		                    <h3 id="temperature-chart-1day-value">27.1</h3>
		                    <p>°C</p>						
	                    </div>
                    </div>
                    <div class="row">
	                    <div class="col-sm-12 sensor-chart-title">
		                    <h4>Letzten 24 Stunden</h4>
	                    </div>	
                    </div>
 		            <div class="row">			
	                    <div class="col-xs-10" style="height: 116px; padding-right: 0px" id="temperature-chart-30days" data-xaxistitle="Zeit" data-yaxistitle="Temperatur in Celsius" data-unit="°C" data-charttitle="Temperatur">
	                        <canvas class="sensor-chart chartjs-render-monitor" style="width: 600px; height: 115px; padding-left: 0px; padding-right: 0px; display: block;" width="600" height="115"></canvas>
	                    </div>
	                    <div class="col-2 sensor-value text-center">
		                    <p><small class="d-sm-hidden">Durchschnittlich</small><span class="d-block d-sm-none">Ø</span></p>
		                    <h3 id="temperature-chart-30days-value">27</h3>
		                    <p>°C</p>						
	                    </div>
                    </div>
                    <div class="row">
	                    <div class="col-sm-12 sensor-chart-title">
		                    <h4>Letzten 30 Tage</h4>
	                    </div>	
                    </div>
	            </div>
            </section><!-- Temperature End -->

            <!-- Humidity -->
            <section class="row primary-background">
	            <div class="col-sm-2">
		            <img src="images/icon_humidity.png" alt="humidity icon" class="sensor-icon">
		            <h4>Luftfeuchtigkeit</h4>
	            </div>
	            <div class="col-sm-10">
	                <div class="row">			
	                    <div class="col-xs-10" style="height: 116px; padding-right: 0px" id="humidity-chart-7days" data-xaxistitle="Zeit" data-yaxistitle="Luftfeuchtigkeit in Prozent" data-unit="RH%" data-charttitle="Luftfeuchtigkeit">
	                        <canvas class="sensor-chart chartjs-render-monitor" style="width: 600px; height: 115px; padding-left: 0px; padding-right: 0px; display: block;" width="600" height="115"></canvas>
	                    </div>
	                    <div class="col-2 sensor-value text-center">
		                    <p>Aktuell</p>
		                    <h3 id="humidity-chart-7days-value">45.9</h3>
		                    <p>RH%</p>						
	                    </div>
                    </div>
                    <div class="row">
	                    <div class="col-sm-12 sensor-chart-title">
		                    <h4>Letzten 7 Tage</h4>
	                    </div>	
                    </div>
	            </div>
            </section><!-- Humidity End -->

            <!-- Light -->
            <section class="row primary-background">
	            <div class="col-sm-2">
		            <img src="images/icon_light.png" alt="light icon" class="sensor-icon">
		            <h4>Helligkeit</h4>
	            </div>
	            <div class="col-sm-10">			
	                <div class="row">			
	                    <div class="col-xs-10" style="height: 116px; padding-right: 0px" id="light-chart-7days" data-xaxistitle="Zeit" data-yaxistitle="Helligkeit in Prozent" data-unit="%" data-charttitle="Helligkeit">
	                        <canvas class="sensor-chart chartjs-render-monitor" style="width: 600px; height: 115px; padding-left: 0px; padding-right: 0px; display: block;" width="600" height="115"></canvas>
	                    </div>
	                    <div class="col-2 sensor-value text-center">
		                    <p>Aktuell</p>
		                    <h3 id="light-chart-7days-value">3.03</h3>
		                    <p>%</p>						
	                    </div>
                    </div>
                    <div class="row">
	                    <div class="col-sm-12 sensor-chart-title">
		                    <h4>Letzten 7 Tage</h4>
	                    </div>	
                    </div>
	            </div>
            </section><!-- Light End -->

            <!-- Pressure -->
            <section class="row primary-background">
	            <div class="col-sm-2">
		            <img src="images/icon_pressure.png" alt="pressure icon" class="sensor-icon">
		            <h4>Luftdruck</h4>
	            </div>
	            <div class="col-sm-10">
	            <div class="row">			
	                <div class="col-xs-10" style="height: 116px; padding-right: 0px" id="pressure-chart-7days" data-xaxistitle="Zeit" data-yaxistitle="Luftdruck in hPa" data-unit="hPa" data-charttitle="Luftdruck">
	                    <canvas class="sensor-chart chartjs-render-monitor" style="width: 600px; height: 115px; padding-left: 0px; padding-right: 0px; display: block;" width="600" height="115"></canvas>
	                </div>
	                <div class="col-2 sensor-value text-center">
		                <p>Aktuell</p>
		                <h3 id="pressure-chart-7days-value">976.3</h3>
		                <p>hPa</p>						
	                </div>
                </div>
                <div class="row">
	                <div class="col-sm-12 sensor-chart-title">
		                <h4>Letzten 7 Tage</h4>
	                </div>	
                </div>
	        </div>
        </section><!-- Pressure End -->

        </div>
	  </div>
  </div>
</template>

<script>
	import Tab from '../../../public/js/sensorsTab.js'
    export default {         
		data: function() {
			return {
				interval: null,
			}
		},
		methods: {
			update: function() {
				this.sensorTab = new Tab.SensorsTab();
				this.sensorTab.Run();
			}
		},
        mounted() {
			console.log('Sensors component mounted.');
			
			this.update();
			this.interval = setInterval(function() {
				this.update();
			}.bind(this), 60000);
		},
		beforeDestroy: function() {
			clearInterval(this.interval);
		}
    }
</script>

<style scoped>
.sensor-icon { background-color:#3979DF; width: 94px; }
.sensor-chart-title { margin-bottom: 20px; }
.sensor-chart-title h4 { margin-top: 5px; }
.sensor-chart { padding-left: 15px; padding-right: 0px; }
.sensor-value { background-color:#3979DF; color: #fff; padding: 15px 0;}
.sensor-value p { margin-bottom: 0px; }
.sensor-value h3 { margin-top: 10px; }
.sensor-chart { height: 116px; }
@media (max-width: 767px) {
	.sensor-icon { width: 70px; }
}
@media (max-width: 991px) {
	.sensor-icon { width: 80px; }
}
</style>