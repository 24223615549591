<template>
    <div>
        <!-- <h1>Logima Tempo Dashboard</h1> -->
        <nav id="header" class="navbar navbar-expand navbar-dark flex-column flex-md-row">
		<a class="navbar-brand" href="javascript:window.location.reload(true)" id="header-banner">
			Logima Tempo Dashboard
		</a>
		<div role="tabpanel" class="collapse navbar-collapse">
			<ul class="navbar-nav flex-row ml-md-auto d-none d-md-flex" role="tablist" id="header-view-selection-buttons">
				<li role="presentation" class="nav-item">
					<router-link :to="{ name: 'daily' }" class="nav-link" aria-controls="daily" role="tab" id="daily-view" exact>
                        Oracel
                    </router-link>
				</li>
                <li role="presentation" class="nav-item">
					<router-link :to="{ name: 'accounts' }" class="nav-link" aria-controls="accounts" role="tab" id="accounts-view">
                        Accounts
                    </router-link>
				</li>
				<li role="presentation"  class="nav-item">
					<router-link :to="{ name: 'monthly' }" class="nav-link" aria-controls="monthly" role="tab" id="monthly-view">
                        Monthly
                    </router-link>
				</li>
                <li role="presentation"  class="nav-item">
					<router-link :to="{ name: 'sensors' }" class="nav-link" aria-controls="sensors" role="tab" id="sensors-view">
                        Sensors
                    </router-link>
				</li>
                <li class="nav-item">
                    <a class="nav-link" :href="logout_route" v-on:click="submitLogoutForm">
                        Logout {{ userName }}
                    </a>
                </li>
			</ul>
		</div>
	    </nav>
        <div class="container-fluid">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                logout_route: 'logout',
                userName: '',
            }
        },
        
        methods: {
            submitLogoutForm() {
                event.preventDefault();
                axios.post('/logout').then(response => {
                    location.reload();
                }).catch(error => {
                    location.reload();
                });
            },
        },

        created() {
            axios.get('/api/user')
                .then(response => this.userName = _.capitalize(response.data));
        },
        mounted() {
            console.log('App component mounted.');
        }
    }
</script>